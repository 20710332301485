<template>
  <div class="container-fluid">
    <br>
    <template>
      <div>
        <b-form>
          <b-form-group>
            <h3>取得報到單</h3>
            <b-form-select
              v-model="selected"
              :options="serials"
              autofocus
              class="col-sm-3 col-lg-3 "
            ></b-form-select>
            <b-button @click="getCheckForm()">取得報到單</b-button>
          </b-form-group>
          <b-form-group>

          </b-form-group>
        </b-form>
      </div>
    </template>
    <div class="col-12 col-sm-12">
      <h3>期初報到單審核</h3>
      <br>
      <b-table
        :fields="rightResults.results.fields" :items="rightResults.results.lists"
        fixed
        hover
        responsive="sm"
        striped
      >
        <template v-slot:cell(room)="row">
          {{ row.value }}
        </template>

        <!-- 顯示個人 -->


        <template v-slot:cell(status)="row">
          {{ row.value }}
        </template>
        <template v-slot:cell(actions)="row">
          <b-button :disabled="row.item.status === '未審核' || row.item.status ==='審核完畢'"
                    class="mr-2"
                    size="sm"
                    @click="verify(row.item.info)"
          >審核
          </b-button>
          <b-button
            row-clicked="showVerifiedDetail"
            size="sm"
            @click="row.toggleDetails"
          >
            顯示詳細信息
          </b-button>
        </template>
        <template #row-details="row">
          <b-card>
            <b-row v-for="data in row.item.info"
                   :key="data"
                   :device="data.device"
                   :form_id="data.form_id"
                   :name="data.name"
                   :other="data.other"
                   :status="data.status"
                   :std_id="data.stu_id"
                   class="mb-2"
            >
              <b-col sm="2">
                姓名:<b>{{ data.name }}</b>
              </b-col>
              <b-col sm="2">
                學號:<b>{{ data.stu_id }}</b>
              </b-col>
              <b-col sm="2">
                設備:
                <b v-if="data.form_id === null">無</b>
                <b v-else-if="data.device.length === 0">我的房間沒問題</b>
                <b v-else>
                  <b v-for="item in data.device"
                     :key="item">
                    {{ chengenWu666(item) }}
                  </b>
                </b>
              </b-col>
              <b-col sm="2">
                其他:<b>{{ data.other !== "" && data.other !== null ? data.other : '無' }}</b>
              </b-col>
              <b-col sm="2">
                狀態:<b>{{ data.status }}</b>
              </b-col>
            </b-row>
          </b-card>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {

  name: "checkForm",
  data: function () {
    return {
      tableCon: {
        fields: [
          {
            key: 'serial',
            label: '名單'
          },
          {
            key: 'action',
            label: '操作'
          }
        ]
      },
      rightResults: {
        results: {
          lists: [],
          originData: [],
          fields: [
            {key: 'room', label: '房號', sortable: true},
            {key: 'status', label: '狀態', sortable: true},
            {key: 'actions', label: '操作'},
          ],
        },
      },
      serials: [],
      selected: '',
      showInfo: false
    }
  },
  mounted: function () {
    this.getSerials();
  },
  methods: {
    // 更新資料
    update: function () {
      axios.get('/api/checkin_form/floorMaster')
      .then((response) => {
        this.rightResults.results.lists = response.data;
      })
      .catch((error) => {
        window.console.log(error);
      })
    },
    // mounted得到serial
    getSerials() {
      axios.get('/api/user/serials?active=true')
      .then((serials) => {
        serials = serials.data.sort().reverse();
        for (let serial of serials) {
          this.serials.push({
            text: serial,
            value: serial
          });
        }
      });
    },
    getCheckForm() {
      axios.get('/api/checkin_form/floorMaster', {
        params: {serial: this.selected}
      })
      .then((res) => {
        this.rightResults.results.lists = res.data
      })
    },
    // 審核按鍵
    verify(form_id) {
      let b = [];
      for (let i = 0; i < form_id.length; i++) {
        b += form_id[i].form_id + " ";
      }
      b = b.split(" ");
      let c = b.pop();
      window.console.log(b)
      window.console.log(c)

      axios.post(
        '/api/checkin_form/floorMaster', {form_id: b}
      ).then(() => {
        window.alert("審核中");
        window.location.reload()
        this.getSerials();
      }).catch((error) => {
        //錯誤代碼
        if (error.response.status === 400 || error.response.data.hasOwnProperty('msg')) {
          window.alert(error.response.data.msg);
          this.upload.sendBtnMsg = '重新審核';
        } else {
          window.alert('不明的錯誤');
          this.upload.sendBtnMsg = '重新審核';
        }
      })

    },

    // 大佬牛逼
    chengenWu666(x) {
      let dict = {
        "null": '我的房間沒問題',
        "bed_board": '床板',
        "table": '書桌',
        "wardrobe": "衣櫃",
        "bathroom": '浴室',
        "shower_head": '蓮蓬頭',
        "washbasin": '洗手台',
        "mirror": '鏡子',
        "toilet": '馬桶',
        "ac": '冷氣',
        "ac_filter": '冷氣濾網',
        "ac_controller": '冷氣遙控器',
        "wall": '牆壁',
        "glass": '陽台圍牆上玻璃片傾斜'
      }
      return dict[x];
    }
  },
}
</script>

<style scoped>
</style>
